import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Hero from '../components/Hero/Hero';

const styles = (theme) => ({
  title: {
    color: 'white',
    fontSize: '36px',
    fontWeight: '700',
    '@media (max-width: 959px)': {
      fontSize: '28px',
      textAlign: 'center',
    },
  },
  subTitle: {
    color: 'white',
    fontSize: '18px',
    fontWeight: '400',
    textAlign: 'center',
    lineHeight: '1.5',
  },
  textField: {
    backgroundColor: 'white',
    width: '100%',
  },
  content: {
    margin: '30px',
  },
  topHeadingContainer: {
    paddingBottom: '50px',
    textAlign: 'center',
    maxWidth: '80%',
    margin: 'auto',
  },
  locationMap: {
    textAlign: 'center',
    paddingTop: '40px !important',
    zIndex: '10',
    overflow: 'hidden',
    position: 'relative',
    paddingBottom: '56.25% !important',
    margin: 'auto',
    '& iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '80%',
      margin: 'auto',
    },
  },
  image: {
    width: '80%',
    filter: 'drop-shadow(12px 16px 20px rgba(18,71,155,0.20))',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
});
const useStyles = makeStyles(styles);

function PickupLocationsMap(props) {
  const { title, subTitle, src } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Hero className={classes.hero}>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          spacing={2}
          className={classes.topHeadingContainer}
        >
          <Grid item xs={12}>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.subTitle}>
              {subTitle}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={8} className={classes.locationMap}>
          <iframe src={src}></iframe>
        </Grid>
      </Hero>
    </div>
  );
}
export default PickupLocationsMap;
