import React from 'react';
import classes from './DownloadAppSection.module.scss';
import { useMediaQuery } from '@material-ui/core';
import theme from '../../../../../remitbee/theme/Theme';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import AppStore from '../../../../../../public/design-system/landing/DownloadAppSection/AppStore.svg';
import PlayStore from '../../../../../../public/design-system/landing/DownloadAppSection/PlayStore.svg';
import Devices from '../../../../../../public/design-system/landing/DownloadAppSection/Devices.webp';
import QRCode from '../../../../../../public/design-system/landing/DownloadAppSection/QRCode.svg';

interface Props {
    title1?: string;
    title2?: string;
    description?: string;
    showQRCode?: boolean;
}

const DownloadAppSection:React.FC<Props> = ({ title1, title2, description, showQRCode = true }) => {
    const isNotTablet = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
    const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);

    return (
        <section>
            <Box className={classes['rb-root']} flexDirection={isNotTablet ? 'row' : 'column'}>
                <Box display='flex' alignItems='center' flexDirection='column' gap={32} className={classes['rb-content']}>
                    <Box display='flex' flexDirection='column' gap={16}>
                        <Box>
                            <Typography variant='displayLg' className={classes['rb-title']} weight='bold' color={theme.palette.background.white} >
                                {title1 ? title1 : "100% Online - "}
                            </Typography>                    
                            <Typography variant='displayLg' className={classes['rb-title']} weight='bold' color={theme.palette.background.white} >
                                {title2 ? title2 : "Your choice of web or app"}
                            </Typography>                   
                        </Box>
                        <Box>
                            <Typography paragraph={true} variant='textMd' color={theme.palette.background.white} >
                            {description ? description : "Created for our customers on the go, the Remitbee app has all the functionality you expect from the website compacted into your iPhone or Android device!"}
                            </Typography>                 
                        </Box>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='center' gap={20}>
                        <Box display='flex' flexDirection='row' gap={12} className={classes['rb-bottom-items-box']}>
                            <Box className={classes['rb-app-box']}>
                                <a target="_blanc" href="https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500">
                                <img
                                    src={AppStore}
                                    alt="App store icon"
                                    width={'100%'}
                                    height={'100%'}
                                    loading="lazy"
                                />
                                </a>
                            </Box> 
                            <Box className={classes['rb-app-box']}>
                                <a target="_blanc" href="https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA">
                                <img
                                    src={PlayStore}
                                    alt="Play store icon"
                                    width={'100%'}
                                    height={'100%'}
                                    loading="lazy"
                                />
                                </a>
                            </Box> 
                        </Box>
                        {showQRCode && isNotMobile && <Box display='flex' alignItems='center' justifyContent='center' flexDirection='column' className={classes['rb-devices']}>
                            <Box display='flex' justifyContent='center'>
                                <Typography variant='textXs' color={theme.palette.background.white}>
                                    |
                                </Typography>
                            </Box>
                            <Box display='flex' justifyContent='center'>
                                <Typography variant='textXs' color={theme.palette.background.white}>
                                    or
                                </Typography>
                            </Box>
                            <Box display='flex' justifyContent='center'>
                                <Typography variant='textXs' color={theme.palette.background.white}>
                                    |
                                </Typography>
                            </Box>
                        </Box>}
                        {showQRCode && isNotMobile && <Box display='flex' alignItems='center' flexDirection='row' justifyContent='center' gap={12}>
                            <Box className={classes['rb-devices']}>
                                <img src={QRCode} />
                            </Box>
                            <Box>
                                <Typography variant='textSm' color={theme.palette.background.white}>
                                    Scan QR with your phone to get the app
                                </Typography>
                            </Box>
                        </Box>}
                    </Box>
                </Box>
                <Box display='flex' justifyContent='flex-end' className={classes['rb-devices']}>
                    <img src={Devices} className={classes['rb-devices-img']} alt='remitbee app download banner' />
                </Box>
            </Box>
        </section>
    )
}

export default DownloadAppSection;
